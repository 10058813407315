import React from "react";

import FeaturesThreeCol from "components/features/ThreeColWithSideImage.js";
import Features from "components/features/VerticalWithAlternateImageAndText.js";
import Footer from "components/footers/MiniCenteredFooter";
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import TrustedBySection from "components/testimonials/TrustedBySection.js";

import tw from "twin.macro";
import SEO from "components/SEO/SEO";

import BackgroundAsImageWithCenteredContent from "../components/hero/BackgroundAsImageWithCenteredContent";
import logo from "../images/spartan/spartan_app_solutions_logo3_head.webp";
import backgroundImage from "../images/spartan/technology-6701504_1920.jpg";
import CustomizeIconImage from "images/spartan/customize-orange-icon.svg";
import FastIconImage from "images/spartan/fast-orange-icon.svg";
import ReliableIconImage from "images/spartan/reliable-orange-icon.svg";
import ShieldIconImage from "images/spartan/shield-orange-icon.svg";
import SimpleIconImage from "images/spartan/simple-orange-icon.svg";
import SupportIconImage from "images/spartan/support-orange-icon.svg";
const HighlightedText = tw.span`text-orange-spartan1`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const defaultCards = [
  {
    imageSrc: ShieldIconImage,
    title: "Secure",
    description:
      "Rest easy with our industry-leading security solutions. We prioritize the protection of your data with cutting-edge safeguards, ensuring your business stays safe from emerging threats. Trust us to keep your operations secure at all times.",
  },
  {
    imageSrc: SupportIconImage,
    title: "Support",
    description:
      "Leverage our 24/7 expert support and our commitment to your success. We work with the industry's best to deliver exceptional service, ensuring you have the assistance you need, whenever you need it. Your satisfaction is our top priority.",
  },
  {
    imageSrc: CustomizeIconImage,
    title: "Customizable",
    description:
      "Experience the flexibility of our customizable solutions, tailored to fit your specific needs. Whether you require small tweaks or extensive adjustments, we deliver bespoke services that align perfectly with your business objectives.",
  },
  {
    imageSrc: ReliableIconImage,
    title: "Reliable",
    description:
      "Count on our proven reliability to keep your business running smoothly. Our services are engineered for maximum uptime, ensuring consistent and dependable performance, so you can focus on what matters most—growing your business.",
  },
  {
    imageSrc: FastIconImage,
    title: "Fast",
    description:
      "Achieve rapid results with our efficient and timely services. We deliver on our promises quickly, without sacrificing quality, so your projects stay on track and on schedule. Experience the speed and precision of our expert team.",
  },
  {
    imageSrc: SimpleIconImage,
    title: "Easy",
    description:
      "Enjoy the simplicity of our user-friendly solutions. Designed with you in mind, our services are intuitive and easy to use, reducing complexity and enhancing productivity. Seamlessly integrate our solutions into your workflow with minimal effort.",
  },
];

export default () => {
  return (
    <>
      <SEO
        title="Home - Spartan App Solutions – Custom-Crafted Mobile and Desktop Applications for Your Unique Needs"
        description="Transform your ideas into powerful mobile applications with Spartan App Solutions. Expert app development for iOS, Android, and custom software needs. Let's build something great together."
        keywords="spartan app, spartan solutions, app solutions, app it solutions, microsoft solutions spartan, cloud solutions spartan,in app solutions, mobile applications, desktop applications, technology solutions, business efficiency, digital transformation, cloud migration services, professional consulting"
      />
      <AnimationRevealPage>
        <BackgroundAsImageWithCenteredContent
          logo={logo}
          HireUs={true}
          headingText="We are consultants <br/> who provide tailored solutions  <br/> to drive your success"
          backgroundImage={backgroundImage}
        />

        <FeaturesThreeCol
          cards={defaultCards}
          subheading={<Subheading>Our Features</Subheading>}
          heading={
            <>
              We have Amazing <HighlightedText>Support.</HighlightedText>
            </>
          }
          description="Whether you're looking to develop a new mobile app, modernize your web platform, or migrate your operations to the cloud, we have the expertise to help you achieve your goals."
        />
        <TrustedBySection />
        <Features />
        <Testimonial />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};

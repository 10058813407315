import React from 'react';

import MainFeature1 from 'components/features/TwoColWithButton.js';
import Footer from 'components/footers/MiniCenteredFooter.js';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import tw from 'twin.macro';
import SEO from 'components/SEO/SEO';
import BackgroundAsImageWithCenteredContent from 'components/hero/BackgroundAsImageWithCenteredContent';
import backgroundImage from 'images/spartan/consulting-3055703_1920.png';

import visionImage from 'images/spartan/business-3731324_640.jpg';
import aboutUsImage from 'images/spartan/desk-1220052_640.jpg';
import solutionImage from 'images/spartan/problem-6609450_640.jpg';
import partnerImage from 'images/spartan/success-2081167_640.jpg';
import logo from 'images/spartan/spartan_app_solutions_logo3_head.webp';
import descriptions from './AboutUs.json';

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const AboutUsPage = (props) => {
  const getImageSrc = (key) => {
    switch (key) {
      case 'aboutUs':
        return aboutUsImage;
      case 'solution':
        return solutionImage;
      case 'partnering':
        return partnerImage;
      case 'vision':
        return visionImage;
      default:
        return '';
    }
  };

  return (
    <>
      <SEO
        title="About Us - Spartan App Solutions"
        description="Discover Spartan App Solutions – a team of skilled app developers dedicated to bringing your app ideas to life. Learn more about our mission, values, and expertise in mobile app development."
        keywords="about us, company, mobile applications, desktop applications, technology solutions, cloud migration, professional consulting"
      />
      <AnimationRevealPage>
        <BackgroundAsImageWithCenteredContent
          headingText={props.title}
          logo={logo}
          backgroundImage={backgroundImage}
        />
        {Object.keys(descriptions).map((key, index) => (
          <MainFeature1
            key={index}
            subheading={(key === 'aboutUs' || key === 'vision') && <Subheading>{descriptions[key].heading}</Subheading>}
            heading={descriptions[key].heading}
            buttonRounded={descriptions[key].buttonRounded}
            imageSrc={getImageSrc(key)}
            description={descriptions[key].description}
            textOnLeft={descriptions[key].textOnLeft}
          />
        ))}
        <Footer />
      </AnimationRevealPage>
    </>
  );
};

export default AboutUsPage;

import React from "react";
import SEO from "components/SEO/SEO";
import Footer from "components/footers/MiniCenteredFooter.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import BackgroundAsImageWithCenteredContent from "../../components/hero/BackgroundAsImageWithCenteredContent";
import backgroundImage from "../../images/spartan/pages/cloud-page.webp";
import logo from "../../images/spartan/spartan_app_solutions_logo3_head.webp";
import FeaturesThreeCol from "components/features/ThreeColWithSideImage.js";
const HighlightedText = tw.span`text-orange-spartan1`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Wrapper = tw.div`
  flex items-center justify-center px-8 py-3
`;
const StyledLink = tw(Link)`
  px-8 py-3 rounded bg-orange-spartan1 text-gray-100
  hocus:bg-orange-spartan2 hocus:text-gray-200 focus:shadow-outline
  border-b-0 cursor-pointer
`;
const defaultCards = [
  {
    title: "Dynamics 365 Integration",
    description:
      "Unlock the full potential of your business with seamless Dynamics 365 integration. We create a unified, powerful platform that simplifies workflows, enhances productivity, and aligns your business processes. By connecting Dynamics 365 with your existing systems, we help you harness data-driven insights and improve decision-making at every level.",
  },
  {
    title: "Expert Guidance for End-to-End Cloud Support",
    description:
      "Our cloud experts are with you every step of the way, ensuring a smooth and efficient transition to the cloud. From initial migration to ongoing management, we provide reliable support to minimize disruptions and maintain operational stability. Whether you're migrating workloads or optimizing your infrastructure, our team ensures your cloud environment evolves with your business needs.",
  },
  {
    title: "Optimize Operations and Drive Excellence",
    description:
      "We tailor cloud solutions to maximize efficiency, reduce costs, and boost operational performance. By leveraging industry best practices, we help your organization streamline operations and achieve scalable growth. Our customized strategies empower you to make the most of your cloud investment, driving productivity and excellence across your business.",
  },
];

const CloudServicesPage = (props) => {
  return (
    <>
      <SEO
        title="Cloud Services | Spartan App Solutions - Cloud Integration & Migration"
        description="Transform your business with Spartan App Solutions' cloud services. We specialize in Dynamics 365 integration, end-to-end cloud support, and operational optimization to drive efficiency and scalable growth."
        keywords="cloud services, Dynamics 365 integration, cloud migration, cloud optimization, cloud infrastructure, Spartan App Solutions, digital transformation, business efficiency, Microsoft cloud solutions, consulting services"
      />
      <AnimationRevealPage>
        <BackgroundAsImageWithCenteredContent
          headingText={props.title}
          logo={logo}
          backgroundImage={backgroundImage}
        />
        <FeaturesThreeCol
          heading={
            <>
              Discover Our
              <HighlightedText>Cloud </HighlightedText>Services.
            </>
          }
          cards={defaultCards}
        ></FeaturesThreeCol> 
        <Footer />
      </AnimationRevealPage>
    </>
  );
};

export default CloudServicesPage;

import React from "react";
import SEO from "components/SEO/SEO";
import Footer from "components/footers/MiniCenteredFooter.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import FeaturesThreeCol from "components/features/ThreeColWithSideImage.js";

import BackgroundAsImageWithCenteredContent from "../../components/hero/BackgroundAsImageWithCenteredContent";
import backgroundImage from "../../images/spartan/pages/ai-page.webp";
import logo from "../../images/spartan/spartan_app_solutions_logo3_head.webp";
import { Link } from "react-router-dom";

const HighlightedText = tw.span`text-orange-spartan1`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Wrapper = tw.div`
  flex items-center justify-center px-8 py-3
`;

const StyledLink = tw(Link)`
  px-8 py-3 rounded bg-orange-spartan1 text-gray-100
  hocus:bg-orange-spartan2 hocus:text-gray-200 focus:shadow-outline
  border-b-0 cursor-pointer
`;

const defaultCards = [
  {
    title: "Empower Your Organization",
    description:
      "Unlock the full potential of artificial intelligence with customized AI models built specifically for your business. We seamlessly integrate advanced APIs, including ChatGPT and other powerful AI tools, to enhance productivity and streamline operations, giving you an edge in today's dynamic market.",
  },
  {
    title: "Digital Transformation",
    description:
      "Navigating the digital era requires more than technology—it demands vision and strategy. We help businesses evolve by guiding their digital transformation journeys, ensuring that every solution we deliver aligns with your goals and prepares you to excel in a fast-paced, ever-changing environment.",
  },
  {
    title: "Lead with AI-Driven Success",
    description:
      "Drive your organization forward with innovative AI solutions that fuel sustainable growth. Our mission is to empower you to thrive, using data-driven insights and advanced automation to propel your business to new heights. With Spartan, the future isn’t just a possibility—it’s within reach.",
  },
];
const AiConsultingPage = (props) => {
  return (
    <>
      <SEO
        title="AI Consulting Services | Spartan App Solutions - Custom AI Models & Digital Transformation"
        description="Transform your business with Spartan App Solutions' AI consulting services. We integrate powerful AI tools like ChatGPT and custom models to enhance operations, drive innovation, and accelerate digital transformation."
        keywords="AI consulting, custom AI models, ChatGPT integration, artificial intelligence solutions, digital transformation, AI-driven innovation, Spartan App Solutions, business automation, advanced AI tools, AI-powered solutions"
      />

      <AnimationRevealPage>
        <BackgroundAsImageWithCenteredContent
          headingText={props.title}
          logo={logo}
          backgroundImage={backgroundImage}
        />
        <FeaturesThreeCol
          subheading={
            <Subheading>
              At Spartan, we are at the forefront of transforming businesses
              with next-generation AI technologies. Whether you're looking to
              optimize operations, enhance customer experiences, or gain a
              competitive edge, our AI-driven solutions are tailored to meet the
              unique challenges of your organization.
            </Subheading>
          }
          heading={
            <>
              Explore the Future with <HighlightedText> AI </HighlightedText>{" "}
              Solutions.
            </>
          }
          cards={defaultCards}
        ></FeaturesThreeCol> 
        <Footer />
      </AnimationRevealPage>
    </>
  );
};

export default AiConsultingPage;

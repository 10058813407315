import React from "react";
import SEO from "components/SEO/SEO";
import Footer from "components/footers/MiniCenteredFooter.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import BackgroundAsImageWithCenteredContent from "../../components/hero/BackgroundAsImageWithCenteredContent";
import backgroundImage from "../../images/spartan/pages/software-page.webp";
import logo from "../../images/spartan/spartan_app_solutions_logo3_head.webp";
import FeaturesThreeCol from "components/features/ThreeColWithSideImage.js";
import { Link } from "react-router-dom";
const HighlightedText = tw.span`text-orange-spartan1`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;

const Wrapper = tw.div`
  flex items-center justify-center px-8 py-3
`;

const StyledLink = tw(Link)`
  px-8 py-3 rounded bg-orange-spartan1 text-gray-100
  hocus:bg-orange-spartan2 hocus:text-gray-200 focus:shadow-outline
  border-b-0 cursor-pointer
`;

const defaultCards = [
  {
    title: "Web Development",
    description:
      "We specialize in crafting and maintaining dynamic websites and web applications that go beyond aesthetics to deliver seamless user experiences. Our solutions are built to drive business growth, ensuring your online presence is both impactful and functional. Whether it's building from the ground up or enhancing existing platforms, we align our strategies with your business goals to create optimized, future-ready web solutions.",
  },
  {
    title: "Mobile App Development",
    description:
      "Our mobile app development services focus on creating innovative, user-friendly apps for iOS and Android platforms. We ensure seamless integration with your systems and prioritize intuitive design to maximize engagement. From ideation to launch, we develop apps that connect with your audience and enhance user satisfaction, empowering businesses to thrive in the digital landscape.",
  },
  {
    title: "Enterprise Software",
    description:
      "For organizations with complex demands, we deliver robust, scalable, and secure enterprise software solutions. Our approach is tailored to meet the unique challenges of large enterprises, focusing on efficiency, security, and scalability. Whether you need custom ERP solutions, CRM systems, or process automation, we provide technology that supports your growth and operational excellence.",
  },
];

const SoftwareDevelopmentPage = (props) => {
  return (
    <>
      <SEO
        title="Software Development Services | Spartan App Solutions"
        description="Maximize your business potential with Spartan App Solutions' custom software solutions. We specialize in web development, mobile apps, and enterprise software tailored to your business needs."
        keywords="software development, custom solutions, mobile app development, enterprise software, web development, Spartan App Solutions, cloud solutions, digital transformation, consulting"
      />
      <AnimationRevealPage>
        <BackgroundAsImageWithCenteredContent
          headingText={props.title}
          logo={logo}
          backgroundImage={backgroundImage}
        />
        <FeaturesThreeCol
          heading={
            <>
              Partner with us for a comprehensive suite of
              <HighlightedText>
                {" "}
                software development services{" "}
              </HighlightedText>{" "}
              designed to unlock your full business potential
            </>
          }
          cards={defaultCards}
        ></FeaturesThreeCol> 

        <Footer />
      </AnimationRevealPage>
    </>
  );
};

export default SoftwareDevelopmentPage;

import React from 'react';
import cloudIconSrc from 'images/spartan/cloud-illustrations.svg';
import softwareIconSrc from 'images/spartan/programming-illustrations.svg';
import consultIconSrc from 'images/default/support-illustration.svg';
import aiIconSrc from 'images/default/ai-illustration.svg';
import styled from 'styled-components';
import tw from 'twin.macro';

import {
  ReactComponent as SvgDotPatternIcon,
} from '../../images/default/dot-pattern.svg';
import { SectionHeading as HeadingTitle } from '../misc/Headings.js';

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,  
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-7/3 flex-shrink-0 h-80 md:h-160 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-orange-spartan1 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-orange-spartan1`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-orange-spartan1 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-orange-spartan1 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-orange-spartan1 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-orange-spartan1 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: softwareIconSrc,
      subtitle: "",
      title: "Software Development",
      description:
      "Elevate your business with our cutting-edge software development services. Our expert developers harness the latest technologies to craft custom software solutions tailored to your specific needs. Whether you're looking to build web or mobile applications, or develop complex software systems, we have the expertise to turn your vision into reality. Say goodbye to outdated processes and embrace streamlined, efficient operations that drive innovation and success. Transform your business today with our advanced solutions designed to enhance productivity and propel you into the future. Choose excellence—choose to thrive.",
      url: "/Services/SoftwareDevelopment"
    },
    {
      imageSrc: aiIconSrc,
      subtitle: "",
      title: "AI Empowerment Consulting",
      description:
      "Lead the digital revolution with Spartan App Solutions' AI consulting services. Our team specializes in crafting bespoke AI models and integrating cutting-edge conversational AI tools to drive your digital transformation. We empower businesses to stay ahead in a digital-first world with tailored AI solutions that offer a competitive edge. Don’t just keep up with the future—embrace it and excel. With our guidance, transform your operations and unlock new levels of success in the digital age. Partner with us to turn innovation into reality and achieve unprecedented growth.",
      url: "/Services/ai-consulting"
    },
    {
      imageSrc: cloudIconSrc,
      subtitle: "",
      title: "Cloud Services",
      description:
      "Unlock the full potential of the cloud with our reliable cloud services and Microsoft Dynamics 365 integration. In today's digital landscape, secure and scalable infrastructure is key to success. Our cloud solutions, coupled with the power of Dynamics 365, ensure your business operates with maximum efficiency and security. Focus on building strong relationships with your customers while we handle your cloud infrastructure needs. Experience unparalleled scalability and performance, empowering you to innovate and expand without limitations. Trust us to deliver the cloud solutions you need to thrive in the digital era.",
      url: "/Services/CloudServices"
    },
    {
      imageSrc: consultIconSrc,
      subtitle: "",
      title: "IT Consulting Services",
      description:
      "Drive your business towards success with our expert IT consulting services. Navigating the complexities of modern business can be challenging, but our experienced consultants are here to guide you. We provide in-depth analysis of your current operations, identify opportunities for improvement, and develop a strategic action plan tailored to your goals. From strategy to implementation, we offer the support and expertise you need to optimize operations, increase efficiency, and accelerate growth. Transform challenges into opportunities with our trusted consulting services, and position your business for long-term success.",
      url: "/Services/it-consulting"
    }
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Our Services</HeadingTitle>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <Link href={card.url}  aria-label={`Go to ${card.title}`} rel="nofollow"  >Learn more</Link>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};

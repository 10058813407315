import React from "react";
import SEO from "components/SEO/SEO";
import Footer from "components/footers/MiniCenteredFooter.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import BackgroundAsImageWithCenteredContent from "../../components/hero/BackgroundAsImageWithCenteredContent";
import backgroundImage from "../../images/spartan/pages/deveops-page.webp";
import logo from "../../images/spartan/spartan_app_solutions_logo3_head.webp";
import FeaturesThreeCol from "components/features/ThreeColWithSideImage.js";
import { Link } from "react-router-dom";
const HighlightedText = tw.span`text-orange-spartan1`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;

const Wrapper = tw.div`
  flex items-center justify-center px-8 py-3
`;

const StyledLink = tw(Link)`
  px-8 py-3 rounded bg-orange-spartan1 text-gray-100
  hocus:bg-orange-spartan2 hocus:text-gray-200 focus:shadow-outline
  border-b-0 cursor-pointer
`;

const defaultCards = [
  {
    title: "Streamline Your Development Lifecycle",
    description:
      "Maximize efficiency and deliver high-quality products faster with DevOps. Through Continuous Integration and Continuous Delivery (CI/CD), we ensure smoother workflows, minimized bottlenecks, and accelerated time-to-market for your applications.",
  },
  {
    title: "Tailored DevOps Solutions",
    description:
      "No two businesses are alike, which is why we offer customized DevOps strategies that fit your unique needs. Our approach leverages the latest tools and methodologies to align with your goals, delivering optimal results while ensuring seamless integration across your infrastructure.",
  },
  {
    title: "Automated Processes for Maximum Productivity",
    description:
      "Empower your teams by automating key processes, enhancing collaboration, and reducing manual workloads. From code deployments to monitoring pipelines, our automated solutions increase efficiency and minimize errors, allowing your team to focus on innovation.",
  },
];

const DevopsPage = (props) => {
  return (
    <>
      <SEO
        title="DevOps Services | Spartan App Solutions - CI/CD & Automation Experts"
        description="Enhance your software delivery with Spartan App Solutions' DevOps services. We specialize in CI/CD pipelines, automated workflows, and customized strategies to streamline your development lifecycle and accelerate time-to-market."
        keywords="DevOps services, CI/CD pipelines, continuous integration, continuous delivery, automation, DevOps consulting, software development lifecycle, workflow automation, Spartan App Solutions, custom DevOps solutions"
      />
      <AnimationRevealPage>
        <BackgroundAsImageWithCenteredContent
          headingText={props.title}
          logo={logo}
          backgroundImage={backgroundImage}
        />
        <FeaturesThreeCol
          subheading={
            <Subheading>
              Experience the power of DevOps by integrating automated workflows
              and tailored solutions to streamline your development lifecycle
              from start to finish!
            </Subheading>
          }
          heading={
            <>
              {" "}
              Optimize Your Development with
              <HighlightedText>DevOps</HighlightedText>
            </>
          }
          cards={defaultCards}
        ></FeaturesThreeCol> 

        <Footer />
      </AnimationRevealPage>
    </>
  );
};

export default DevopsPage;

import React from "react";
import SEO from "components/SEO/SEO";
import Footer from "components/footers/MiniCenteredFooter.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import FeaturesThreeCol from "components/features/ThreeColWithSideImage.js";

import BackgroundAsImageWithCenteredContent from "../../components/hero/BackgroundAsImageWithCenteredContent";
import backgroundImage from "../../images/spartan/team-4864048_640.jpg";
import logo from "../../images/spartan/spartan_app_solutions_logo3_head.webp";
import { Link } from "react-router-dom";
const HighlightedText = tw.span`text-orange-spartan1`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;

const Wrapper = tw.div`
  flex items-center justify-center px-8 py-3
`;

const StyledLink = tw(Link)`
  px-8 py-3 rounded bg-orange-spartan1 text-gray-100
  hocus:bg-orange-spartan2 hocus:text-gray-200 focus:shadow-outline
  border-b-0 cursor-pointer
`;

const defaultCards = [
  {
    title: "Strategic Planning",
    description:
      "We guide your business with expert strategic planning, unlocking its full potential and positioning it for sustainable growth. Our consultants partner with you to set clear objectives, identify opportunities, and create actionable roadmaps that align with your long-term vision.",
  },
  {
    title: "Comprehensive Services",
    description:
      "Our consulting services cover a broad range of areas, from process optimization and digital transformation to business growth strategies. Whether you need help improving operations or scaling your business, we offer the expertise you need to stay competitive in a fast-changing market.",
  },
  {
    title: "Customized Solutions",
    description:
      "We understand that every business is unique. That’s why we deliver personalized solutions designed to drive growth, streamline operations, and cut costs. By tailoring strategies to your specific needs, we help you enhance efficiency, improve performance, and achieve measurable results.",
  },
];
const ItConsultingPage = (props) => {
  return (
    <>
      <SEO
        title="IT Consulting Services | Spartan App Solutions - Strategic Growth & Digital Transformation"
        description="Enhance your business with expert IT consulting services from Spartan App Solutions. We specialize in strategic planning, process optimization, and customized solutions to drive sustainable growth and operational efficiency."
        keywords="IT consulting, strategic planning, business consulting, digital transformation, process optimization, Spartan App Solutions, business growth strategies, custom solutions, technology consulting, operational efficiency"
      />
      <AnimationRevealPage>
        <BackgroundAsImageWithCenteredContent
          headingText={props.title}
          logo={logo}
          backgroundImage={backgroundImage}
        />
        <FeaturesThreeCol
          subheading={
            <Subheading>
              Let us guide you through your cloud journey—connect, optimize, and
              grow with confidence.
            </Subheading>
          }
          heading={
            <>
              Partner with us to take{" "}
              <HighlightedText>your business </HighlightedText> to the next
              level—because your success is our mission.
            </>
          }
          cards={defaultCards}
        ></FeaturesThreeCol> 

        <Footer />
      </AnimationRevealPage>
    </>
  );
};

export default ItConsultingPage;

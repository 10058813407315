import React from "react";

import ContactUs from "pages/ContactUs";
import Home from "pages/Home";
import Services from "pages/Services/services";
import SoftwareDevelopment from "pages/Services/softwareDevelopment";
import CloudServices from "pages/Services/cloudServices";
import Analytics from "pages/Services/analytics";
import AiConsulting from "pages/Services/ai-consulting";
import Devops from "pages/Services/devops";
import ItConsulting from "pages/Services/it-consulting";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GlobalStyles from "styles/GlobalStyles";

import { EmailProvider } from "./contexts/EmailContext";
import AboutUs from "./pages/AboutUs/AboutUs";
import Blog from "./pages/Blogs/blog";
import BlogHttpHandlingCallError from "./pages/Blogs/http-handling-call-error";
import BlogMockApi from "./pages/Blogs/mock-api";
import BlogMockWIthAi from "./pages/Blogs/mock-with-ai";
import { HelmetProvider } from "react-helmet-async";
export default function App() { 
  return (
    <>
      <HelmetProvider>
        <EmailProvider>
          <GlobalStyles />
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/Services/Services"
                element={<Services title="Services" />}
              />
              <Route
                path="/Services/SoftwareDevelopment"
                element={<SoftwareDevelopment title="Software Development" />}
              />
              <Route
                path="/Services/CloudServices"
                element={<CloudServices title="Cloud Services" />}
              />
              <Route
                path="/Services/analytics"
                element={<Analytics title="Analytics Services" />}
              />
              <Route
                path="/Services/ai-consulting"
                element={<AiConsulting title="AI Consulting" />}
              />
              <Route
                path="/Services/devops"
                element={<Devops title="Devops" />}
              />
              <Route
                path="/Services/it-consulting"
                element={<ItConsulting title="IT Consulting" />}
              />
              <Route path="/AboutUs" element={<AboutUs title="About Us" />} />
              <Route path="/Blog" element={<Blog title="Blogs" />} />
              <Route
                path="/ContactUs"
                element={<ContactUs title="Contact Us" />}
              />
              <Route path="/HireUS" element={<ContactUs title="Hire Us" />} />
              <Route
                path="/Blog/http-handling-call-error"
                element={
                  <BlogHttpHandlingCallError title="Handling HTTP Call Errors" />
                }
              />
              <Route
                path="/Blog/mock-api"
                element={<BlogMockApi title="Mock APIs" />}
              />
              <Route
                path="/Blog/mock-with-ai"
                element={<BlogMockWIthAi title="Mock With AI" />}
              />
            </Routes>
          </Router>
        </EmailProvider>
      </HelmetProvider>
    </>
  );
}

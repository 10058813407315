import React from "react";
import { SectionHeading } from "components/misc/Headings.js";
import styled from "styled-components";
import tw from "twin.macro";

import LazyLoad from "react-lazyload";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";

import BisLogo from "images/spartan/TrustedImages/BIS-Logo.png";
import IronEagleXLogo from "images/spartan/TrustedImages/IronEagleX.png";
import TiltledOpticsLogo from "images/spartan/TrustedImages/TiltledOptics-logo.png";
import oshrcLogo from "images/spartan/TrustedImages/oshrc-logo.png";
import blueobsidianLogo from "images/spartan/TrustedImages/blueobsidian-solutions-logo-transparent.png";

const Heading = tw(SectionHeading)``;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full bg-gray-100`}
    img {
      ${tw`w-24 h-24`}
    }
  }

  .title {
    ${tw` font-bold text-lg leading-none py-4`}
  }
`;

const Logos = styled.span`
  ${tw`w-24 h-24 bg-blue-blueTilted`}
`;

const heading = "Trusted By";
export default ({
  cards = [
    {
      imageSrc: BisLogo,
      title: "Beacon Insurance Solutions",
    },
    {
      imageSrc: IronEagleXLogo,
      title: "Iron EagleX",
    },
    {
      imageSrc: TiltledOpticsLogo,
      title: "Tiltled Optics Solutions",
    },
    {
      imageSrc: oshrcLogo,
      title: "OSHRC",
    },
    {
      imageSrc: blueobsidianLogo,
      title: "Blueobsidian Solutions",
    },
  ],

  imageContainerCss = null,
  imageCss = null,
}) => {
  return (
    <Container>
      {heading && <Heading>{heading}</Heading>}
      <ContentWithPaddingXl>
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card shadow={true} className="bg-[#FAFAFA] px-5">
                {card.title === "Tiltled Optics Solutions" ? (
                  <Logos>
                    <span className="imageContainer" css={imageContainerCss}>
                      <LazyLoad height={200} offset={100}>
                        <img src={card.imageSrc} alt={card.title} css={imageCss} />
                      </LazyLoad>
                    </span>
                  </Logos>
                ) : (
                  <span className="imageContainer" css={imageContainerCss}>
                    <LazyLoad height={200} offset={100}>
                      <img src={card.imageSrc} alt={card.title} css={imageCss} />
                    </LazyLoad>
                  </span>
                )}
                <span className="title">{card.title}</span>
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};

import React, { useState } from "react";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import LazyLoad from "react-lazyload";
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

const serviceLinks = [
  { href: "/Services/SoftwareDevelopment", label: "Software Development" },
  { href: "/Services/CloudServices", label: "Cloud Services" },
  { href: "/Services/analytics", label: "Analytics Services" },
  { href: "/Services/ai-consulting", label: "AI Consulting" },
  { href: "/Services/devops", label: "DevOps" },
  { href: "/Services/it-consulting", label: "IT Consulting" },
  { href: "/Services/Services", label: "Full List of Services" },
];

const activeLinkStyle = tw`border-orange-spartan1 text-orange-spartan1`;

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-orange-spartan1 hocus:text-orange-spartan1
  block lg:inline-block lg:text-center
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-orange-spartan1 text-gray-100
  hocus:bg-orange-spartan2 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center justify-center font-black border-b-0 text-2xl! ml-0!`}

  img {
    ${tw`w-12 mr-3`}
  }

  @media (max-width: 768px) {
    ${tw`flex-col items-center text-center justify-center`}
    img {
      ${tw`mr-0 mb-4`}
    }
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-end lg:hidden`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-orange-spartan1 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed absolute top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-end items-center
`;

const DropdownContent = styled.div`
  ${tw`absolute hidden bg-white text-black shadow-lg group-hover:block items-center text-center`}
  margin-bottom: 0.1rem;
  transform: translateX(-20%);
`;
const DropdownLink = tw.a`block px-4 py-2 text-sm hover:text-orange-spartan1`;


const MobileDropdownContent = styled.div`
  ${tw`absolute bg-white shadow-md rounded z-50 w-full left-0 text-black`}
`;

const MobileDropdownLink = styled(NavLink)`
  ${tw`block  text-base font-semibold text-black   `}
`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  logo,
  logoText,
  className,
  collapseBreakpointClass = "lg",
}) => {
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown state
  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();

  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];
  logoText = logoText || "Spartan App Solutions";

  const defaultLogoLink = (
    <LogoLink href="/">
      <LazyLoad height={200} offset={100}>
        <img src={logo} alt="Spartan App Solutions logo" />
      </LazyLoad>
      <div dangerouslySetInnerHTML={{ __html: logoText }} />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;

  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev); // Toggle function

  const Dropdown = ({ links }) => (
    <div className="relative group">
      <NavLink as="button">Services</NavLink>
      <DropdownContent>
        {links.map((link) => (
          <DropdownLink key={link.href} href={link.href}>
            {link.label}
          </DropdownLink>
        ))}
      </DropdownContent>
    </div>
  );
  return (
    <Header className={className || "header-light"}>
      <div css={tw`flex flex-1 items-center`}>{logoLink}</div>

      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        <NavLink href="/">Home</NavLink>

        {/* Dropdown logic */}
        <div className="relative">
          <Dropdown links={serviceLinks} />
        </div>

        <NavLink href="/AboutUs">About Us</NavLink>
        <NavLink href="/Blog">Blogs</NavLink>
        <NavLink href="/ContactUs">Contact Us</NavLink>
        <NavLinks key={2}>
          <PrimaryLink href="/ContactUs">Hire Us</PrimaryLink>
        </NavLinks>
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks ? (
            <CloseIcon tw="w-6 h-6" />
          ) : (
            <MenuIcon tw="w-6 h-6" />
          )}
        </NavToggle>
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          <NavLink href="/">Home</NavLink>

          <div className="relative">
            <NavLink href="#" onClick={toggleDropdown}>
              Services
            </NavLink>
            {isDropdownOpen && (
      <MobileDropdownContent>
        {serviceLinks.map((link, index) => (
          <MobileDropdownLink key={index} href={link.href}>
            {link.label}
          </MobileDropdownLink>
        ))}
      </MobileDropdownContent>
    )}
          </div>

          <NavLink href="/AboutUs">About Us</NavLink>
          <NavLink href="/Blog">Blogs</NavLink>
          <NavLink href="/ContactUs">Contact Us</NavLink>
        </MobileNavLinks>
      </MobileNavLinksContainer>
    </Header>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};

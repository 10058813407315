import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'twin.macro';
import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js';
import { SectionDescription } from 'components/misc/Typography.js';
import { ReactComponent as SvgDecoratorBlob3 } from 'images/default/svg-decorator-blob-3.svg';

const Container = tw.div`relative`;
const HorizontalLayout = styled.div`
  ${tw`flex flex-col lg:flex-row  justify-between max-w-screen-lg mx-auto py-20 md:py-24 gap-8`}
`;

const ContentContainer = styled.div`
  ${tw`flex-1`}
`;

const MapContainer = styled.div`
  ${tw`flex-1 w-full lg:max-w-md h-96 lg:h-auto`}
`;

const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center lg:text-left`;
const VerticalSpacer = tw.div`mt-10 w-full`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap  `}
`;

const Column = styled.div`${tw`md:w-1/2 lg:w-1/3 max-w-sm`}`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }
  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }
  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }
  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48`}
`;

const MapEmbed = ({ src }) => (
  <iframe
    src={src}
    width="100%"
    height="100%"
    style={{ border: 0 }}
    allowFullScreen=""
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
  ></iframe>
);

const FeatureSection = ({
  cards = null,
  heading = 'Our Office',
  subheading = '',
  description = '',
}) => {
  const defaultCards = [
    { title: 'Secure', description: 'We strictly only deal with vendors that provide top notch security.' },
    { title: '24/7 Support', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    { title: 'Reliable', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    { title: 'Easy', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    { title: 'Customizable', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    { title: 'Fast', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
  ];

  const cardData = cards ?? defaultCards;

  return (
    <Container>
      <HorizontalLayout>
        <ContentContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
          {/* <VerticalSpacer /> */}
          <ThreeColumnContainer>
            {cardData.map((card, i) => (
              <Column key={i}>
                <Card>
                  <span className="textContainer">
                    <span className="title">{card.title ?? 'Fully Secure'}</span>
                    <p className="description">
                      {card.description ?? 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'}
                    </p>
                  </span>
                </Card>
              </Column>
            ))}
          </ThreeColumnContainer>
        </ContentContainer>

        <MapContainer>
          <MapEmbed src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3527.1431239919193!2d-82.64746922452693!3d27.866873476091374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2e680e4bcffff%3A0x290c1409fbe21b6d!2sSpartan%20App%20Solutions!5e0!3m2!1sen!2sin!4v1729343300607!5m2!1sen!2sin" />
        </MapContainer>
      </HorizontalLayout>

      {/* <DecoratorBlob /> */}
    </Container>
  );
};

FeatureSection.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
};

export default FeatureSection;

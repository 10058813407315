import React from 'react';

import Footer from 'components/footers/MiniCenteredFooter.js';
import ContactUsForm from 'components/forms/TwoColContactUsWithIllustrationFullForm.js';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import tw from 'twin.macro';
import SEO from 'components/SEO/SEO';
import BackgroundAsImageWithCenteredContent from '../components/hero/BackgroundAsImageWithCenteredContent';
import backgroundImage from '../images/spartan/contact-4045623_1920.jpg';
import logo from '../images/spartan/spartan_app_solutions_logo3_head.webp';
import ContactDetails from './../components/cards/OneColContactDetails'

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

const ContactUsPage = (props) => {
  return (
    <>
      <SEO
        title="Contact Us - Spartan App Solutions"
        description="Get in touch with Spartan App Solutions for expert mobile and desktop applications, cloud migration, digital transformation, and consulting services. Contact us today to start your project."
        keywords="contact, get in touch, mobile applications, desktop applications, cloud migration, digital transformation, consulting"
      />
      <AnimationRevealPage>
        <BackgroundAsImageWithCenteredContent
          headingText={props.title}
          logo={logo}
          backgroundImage={backgroundImage}
        />
        <ContactUsForm />
         
        <ContactDetails
          cards={[
            {
              title: "Florida",
              description: (
                <>
                  <Address>
                    <AddressLine>10460 Roosevelt Blvd N</AddressLine>
                    <AddressLine>Suite 153</AddressLine>
                    <AddressLine>St. Petersburg, FL 33716</AddressLine>
                  </Address>
                  <Email>support@spartanappsolutions.com</Email>
                </>
              )
            }
          ]}
        /> 
       
        <Footer />
      </AnimationRevealPage>
    </>
  );
};

export default ContactUsPage;

import React from "react";
import SEO from "components/SEO/SEO";
import Footer from "components/footers/MiniCenteredFooter.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import BackgroundAsImageWithCenteredContent from "../../components/hero/BackgroundAsImageWithCenteredContent";
import backgroundImage from "../../images/spartan/pages/analytics-page.webp";
import logo from "../../images/spartan/spartan_app_solutions_logo3_head.webp";
import FeaturesThreeCol from "components/features/ThreeColWithSideImage.js";
import { Link } from "react-router-dom";
const HighlightedText = tw.span`text-orange-spartan1`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;

const Wrapper = tw.div`
  flex items-center justify-center px-8 py-3
`;

const StyledLink = tw(Link)`
  px-8 py-3 rounded bg-orange-spartan1 text-gray-100
  hocus:bg-orange-spartan2 hocus:text-gray-200 focus:shadow-outline
  border-b-0 cursor-pointer
`;

const defaultCards = [
  {
    title: "Actionable Insights",
    description:
      "Empower your business with data-driven insights that pave the way for informed and strategic decision-making. Our analytics solutions deliver clear, actionable information, helping you confidently chart the best course for growth.",
  },
  {
    title: "Advanced Tools",
    description:
      "We leverage state-of-the-art analytics techniques and cutting-edge tools to turn complex data into meaningful visualizations. These insights enable you to see the bigger picture, identifying opportunities and areas for optimization with precision and clarity.",
  },
  {
    title: "Unlock Growth Potential",
    description:
      "Discover key trends, hidden patterns, and untapped opportunities that drive innovation and fuel business expansion. With our analytics solutions, we help you stay ahead of the curve, transforming data into a catalyst for continuous growth and new possibilities.",
  },
];

const AnalyticsPage = (props) => {
  return (
    <>
      <SEO
        title="Analytics Services | Spartan App Solutions - Data-Driven Insights & Growth"
        description="Harness the power of analytics with Spartan App Solutions. We provide actionable insights, advanced tools, and data-driven strategies to unlock growth potential and optimize business performance."
        keywords="analytics services, data-driven insights, business analytics, data visualization, growth strategies, analytics consulting, Spartan App Solutions, business intelligence, data optimization, actionable insights"
      />

      <AnimationRevealPage>
        <BackgroundAsImageWithCenteredContent
          headingText={props.title}
          logo={logo}
          backgroundImage={backgroundImage}
        />
        <FeaturesThreeCol
          subheading={
            <Subheading>
              Let us help you harness the power of analytics to unlock the full
              potential of your business.
            </Subheading>
          }
          heading={
            <>
              Transform Your Business with
              <HighlightedText> Analytics</HighlightedText>
            </>
          }
          cards={defaultCards}
        ></FeaturesThreeCol> 

        <Footer />
      </AnimationRevealPage>
    </>
  );
};

export default AnalyticsPage;
